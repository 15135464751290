const translations = {
    success: {
        loaded: 'Successfully loaded'
    },
    error: {},
    warning: {},
    info: {}
};

export default translations;
