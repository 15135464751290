import React from 'react';
import './IframePage.css';

const Home = () => {
    const iframeSrc = 'https://gpt.eller.uk';

    return (
        <div className="iframe-container">
            <iframe
                title="Chat GPT"
                src={iframeSrc}
                className="fullscreen-iframe"
            />
        </div>
    );
};

export default Home;