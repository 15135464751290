const translations = {
    success: {
        loaded: 'Carregado com sucesso'
    },
    error: {},
    warning: {},
    info: {}
};

export default translations;
